import React, { useEffect } from 'react';
const EmbedTweet = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <blockquote className="twitter-tweet">
      <p lang="en" dir="ltr">
        <a href="https://twitter.com/harryscorn57503/status/1847682105335267774">
          View Tweet
        </a>
      </p>
    </blockquote>
  );
};

export default EmbedTweet;